import { SwitchCarry } from './../api/funds/switchRules';
export enum Words {

  /** Common */
  OK = 'OK',
  Close = 'Close',
  Cancel = 'Cancel',
  Confirm = 'Confirm',
  Clear = 'Clear',
  Loading = 'Loading',
  BackHome = 'BackHome',
  UG_Uppercase = 'UG_Uppercase',
  Total = 'Total',
  ButtonView = 'ButtonView',
  ButtonDownload = 'ButtonDownload',
  ButtonFullDownload = 'ButtonFullDownload',
  ButtonReport = 'ButtonReport',
  ButtonSearch = 'ButtonSearch',

  ActionCapture = 'ActionCapture',
  ActionPrint = 'ActionPrint',

  /** System Errors */
  ErrorOnRead = 'ErrorOnRead',
  ErrorOnSessionExpired = 'ErrorOnSessionExpired',
  ErrorAccountInvalid = 'ErrorAccountInvalid',
  ErrorSystemUnexpected = 'ErrorSystemUnexpected',

  /** Account */
  AccountPasswd_Title = 'AccountPasswd_Title',
  // AccountPasswd_CurrentPwd = 'AccountPasswd_CurrentPwd',

  AccountPasswd_PwdCurrent = 'AccountPasswd_PwdCurrent',
  AccountPasswd_PwdCurrent_Placeholder = 'AccountPasswd_PwdCurrent_Placeholder',
  AccountPasswd_PwdNew = 'AccountPasswd_PwdNew',
  AccountPasswd_PwdNew_Placeholder = 'AccountPasswd_PwdNew_Placeholder',
  AccountPasswd_PwdConf = 'AccountPasswd_PwdConf',
  AccountPasswd_PwdConf_Placeholder = 'AccountPasswd_PwdConf_Placeholder',

  AccountPasswordReset_Title = 'AccountPasswordReset_Title',
  AccountPasswordChanged_Title = 'AccountPasswordChanged_Title',
  AccountPasswordChanged_Desc = 'AccountPasswordChanged_Desc',
  AccountPasswordChange_Confirm = 'AccountPasswordChange_Confirm',

  AccountForget_Title = 'AccountForget_Title',
  AccountForget_HolderId = 'AccountForget_HolderId',
  AccountForget_HolderId_Placeholder = 'AccountForget_HolderId_Placeholder',
  AccountForget_HolderId_Help = 'AccountForget_HolderId_Help',
  AccountForget_Name = 'AccountForget_Name',
  AccountForget_Name_Placeholder = 'AccountForget_Name_Placeholder',
  AccountForget_Name_Help = 'AccountForget_Name_Help',

  AccountForget_Sent_Title = 'AccountForget_Sent_Title',
  AccountForget_Sent_Desc = 'AccountForget_Sent_Desc',

  AccountForgetReset_Title = 'AccountForgetReset_Title',
  AccountForgetReset_Confirm = 'AccountForgetReset_Confirm',
  AccountForgetResetInvalid_Title = 'AccountForgetResetInvalid_Title',
  AccountForgetResetInvalid_Desc = 'AccountForgetResetInvalid_Desc',
  AccountForgetResetInvalid_Retry = 'AccountForgetResetInvalid_Retry',

  AccountSignin_Title = 'AccountSignin_Title',
  AccountSignin_Usercode = 'AccountSignin_Usercode',
  AccountSignin_Usercode_Placeholder = 'AccountSignin_Usercode_Placeholder',
  AccountSignin_Password = 'AccountSignin_Password',
  AccountSignin_Password_Placeholder = 'AccountSignin_Password_Placeholder',
  AccountSignin_Login = 'AccountSignin_Login',
  AccountSignin_Forget = 'AccountSignin_Forget',
  AccountSignin_Newin = 'AccountSignin_Newin',
  AccountSignin_Signup = 'AccountSignin_Signup',
  AccountSignin_Error_409_Title = 'AccountSignin_Error_409_Title',
  AccountSignin_Error_409_Msg = 'AccountSignin_Error_409_Msg',
  AccountSignin_Conflict_Title = 'AccountSignin_Conflict_Title',
  AccountSignin_Conflict_Message = 'AccountSignin_Conflict_Message',
  AccountSignin_Conflict_Warning = 'AccountSignin_Conflict_Warning',

  AccountSigninOTP_Title = 'AccountSigninOTP_Title',
  AccountSigninOTP_Desc = 'AccountSigninOTP_Desc',
  AccountSigninOTP_Code = 'AccountSigninOTP_Code',
  AccountSigninOTP_CodePlaceholder = 'AccountSigninOTP_CodePlaceholder',
  AccountSigninOTP_TimeLimit = 'AccountSigninOTP_TimeLimit',
  AccountSigninOTP_Failed = 'AccountSigninOTP_Failed',
  AccountSigninOTP_Expired = 'AccountSigninOTP_Expired',
  AccountSigninOTP_TotalLimit = 'AccountSigninOTP_TotalLimit',
  AccountSigninOTP_Resend = 'AccountSigninOTP_Resend',
  AccountSigninOTP_ResendCountdown = 'AccountSigninOTP_ResendCountdown',
  AccountSigninOTP_Submit = 'AccountSigninOTP_Submit',
  AccountSigninOTP_Problem = 'AccountSigninOTP_Problem',
  AccountSigninOTP_Failed_Title = 'AccountSigninOTP_Failed_Title',
  AccountSigninOTP_Failed_Desc = 'AccountSigninOTP_Failed_Desc',
  AccountSigninOTP_ResendExceeded_Desc = 'AccountSigninOTP_ResendExceeded_Desc',

  AccountSignupForm_Title = 'AccountSignupForm_Title',
  AccountSignupForm_Desc = 'AccountSignupForm_Desc',
  AccountSignupForm_Cancel = 'AccountSignupForm_Cancel',
  AccountSignupForm_Agree = 'AccountSignupForm_Agree',
  AccountSignupForm_Submit = 'AccountSignupForm_Submit',
  AccountSignupForm_Exists = 'AccountSignupField_Form_Exists',
  AccountSignupForm_Login = 'AccountSignupField_Form_Login',
  AccountSignupSuccess_Title = 'AccountSignupSuccess_Title',
  AccountSignupSuccess_Desc = 'AccountSignupSuccess_Desc',
  AccountSignupSuccess_OK = 'AccountSignupSuccess_OK',

  AccountSignupPending_Title = 'AccountSignupPending_Title',
  AccountSignupPending_Desc = 'AccountSignupPending_Desc',
  AccountSignupPending_OK = 'AccountSignupPending_OK',
  AccountSignupField_ID_Title = 'AccountSignupField_ID_Title',
  AccountSignupField_ID_Placeholder = 'AccountSignupField_ID_Placeholder',
  AccountSignupField_ID_Help = 'AccountSignupField_ID_Help',
  AccountSignupField_Name_Title = 'AccountSignupField_Name_Title',
  AccountSignupField_Name_Placeholder = 'AccountSignupField_Name_Placeholder',
  AccountSignupField_Name_Help = 'AccountSignupField_Name_Help',
  AccountSignupField_Usercode_Title = 'AccountSignupField_Usercode_Title',
  AccountSignupField_Usercode_Placeholder = 'AccountSignupField_Usercode_Placeholder',
  AccountSignupField_Usercode_Help = 'AccountSignupField_Usercode_Help',
  AccountSignupField_Password_Title = 'AccountSignupField_Password_Title',
  AccountSignupField_Password_Placeholder = 'AccountSignupField_Password_Placeholder',
  AccountSignupField_Password_Help = 'AccountSignupField_Password_Help',
  AccountSignupField_PasswordConf_Title = 'AccountSignupField_PasswordConf_Title',
  AccountSignupField_PasswordConf_Placeholder = 'AccountSignupField_PasswordConf_Placeholder',
  AccountSignupField_PasswordConf_Help = 'AccountSignupField_PasswordConf_Help',
  AccountSignupField_Email_Title = 'AccountSignupField_Email_Title',
  AccountSignupField_Email_Placeholder = 'AccountSignupField_Email_Placeholder',
  AccountSignupField_Email_Help = 'AccountSignupField_Email_Help',
  AccountSignupField_Phone_Title = 'AccountSignupField_Phone_Title',
  AccountSignupField_Phone_Placeholder = 'AccountSignupField_Phone_Placeholder',
  AccountSignupField_Phone_Help = 'AccountSignupField_Phone_Help',

  AccountSignupValidate_ID = 'AccountSignupValidate_ID',
  AccountSignupValidate_ID_Number = 'AccountSignupValidate_ID_Number',
  AccountSignupValidate_Name = 'AccountSignupValidate_Name',

  AccountSignupValidate_Usercode_Require = 'AccountSignupValidate_Usercode_Require',
  AccountSignupValidate_Usercode_Invalid = 'AccountSignupValidate_Usercode_Invalid',
  AccountSignupValidate_Usercode_Length = 'AccountSignupValidate_Usercode_Length',

  AccountSignupValidate_Password = 'AccountSignupValidate_Password',
  AccountSignupValidate_Password_Invalid = 'AccountSignupValidate_Password_Invalid',
  AccountSignupValidate_Password_Length = 'AccountSignupValidate_Password_Length',
  AccountSignupValidate_Password_Incorrect = 'AccountSignupValidate_Password_Incorrect',
  AccountSignupValidate_Confirm = 'AccountSignupValidate_Confirm',
  AccountSignupValidate_Confirm_Incorrect = 'AccountSignupValidate_Confirm_Incorrect',
  AccountSignupValidate_Email = 'AccountSignupValidate_Email',
  AccountSignupValidate_Email_Incorrect = 'AccountSignupValidate_Email_Incorrect',
  AccountSignupValidate_Phone = 'AccountSignupValidate_Phone',

  AccountSignupConfirm_Title = 'AccountSignupConfirm_Title',
  AccountSignupConfirm_Desc = 'AccountSignupConfirm_Desc',
  AccountSignupConfirm_Modify = 'AccountSignupConfirm_Modify',
  AccountSignupConfirm_Continue = 'AccountSignupConfirm_Continue',

  /** Funds */
  Fund_GCMF = 'Fund_GCMF',
  Fund_UGWAF = 'Fund_UGWAF',
  Fund_SOF = 'Fund_SOF',
  FundReports = 'FundReports',
  FundFactSheet = 'FundFactSheet',
  FundHistoricalNav = 'FundHistoricalNav',
  FundFinancialReport = 'FundFinancialReport',
  FundHistoricalFactSheet = 'FundHistoricalFactSheet',
  FundPerformance = 'FundPerformance',
  FundNavDate = 'FundNavDate',

  FundPerformanceTrend = 'FundPerformanceTrend',
  FundTo = 'FundTo',
  FundEstimated = 'FundEstimated',
  FundRateOfReturn = 'FundRateOfReturn',
  FundAnnualizedReturn = 'FundAnnualizedReturn',
  FundAnnualizedVolatility = 'FundAnnualizedVolatility',
  FundSharpeRatio = 'FundSharpeRatio',
  FundChartNote_1 = 'FundChartNote_1',
  FundChartNote_2 = 'FundChartNote_2',
  FundStrategySection = 'FundStrategySection',
  FundWatchVideo = 'FundWatchVideo',

  FundClass = 'FundClass',
  FundFinalNAV = 'FundFinalNAV',
  FundEstimateNAV = 'FundEstimateNAV',
  FundNote_Xiamen = 'FundNote_Xiamen',
  FundPerfNote_1 = 'FundPerfNote_1',
  FundCorrection = 'FundCorrection',
  

  /** About */
  AboutUG = 'AboutUG',
  AboutPhilosophy = 'AboutPhilosophy',
  AboutHistory = 'AboutHistory',
  AboutNewsroom = 'AboutNewsroom',
  AboutHonor = 'AboutHonor',
  AboutInfo = 'AboutInfo',

  /** Common: 會員 */
  Login = 'Login',
  Logout = 'Logout',
  Signup = 'Signup',
  UserAccount = 'UserAccount',
  ChangePassword = 'ChangePassword',

  /** Menu */
  MenuHome = 'MenuHome',
  MenuAbout = 'MenuAbout',
  MenuAward = 'MenuAward',
  MenuInvestment = 'Investment',
  MenuFunds = 'Funds',
  MenuNews = 'MenuNews',
  MenuComments = 'MenuComments',
  MenuAnnouncement = 'MenuAnnouncement',
  MenuAnnouncement2 = 'MenuAnnouncement2',
  MenuForms = 'MenuForms',
  MenuRecruit = 'MenuRecruit',
  MenuContact = 'MenuContact',
  MenuAssetOverview = 'MenuAssetOverview',
  MenuTransactionHistory = 'MenuTransactionHistory',
  MenuUser = 'MenuUser',
  MenuClients = 'MenuClients',
  MenuEstimateRedemptionCharge = 'MenuEstimateRedemptionCharge',
  MenuInvestmentOverview = 'MenuInvestmentOverview',
  

  /** 首頁 */
  HomeCoverSlogan_1 = 'HomeCoverSlogan_1',
  HomeCoverBtn = 'HomeCoverBtn',

  HomePioneer = 'HomePioneer',
  HomePioneerDetail = 'HomePioneerDetail',
  HomeAumText = 'HomeAumText',
  HomeAumUnit = 'HomeAumUnit',
  HomeSecText = 'HomeSecText',
  HomeSecContent = 'HomeSecContent',
  HomeAimaText = 'HomeAimaText',
  HomeAimaContent = 'HomeAimaContent',

  HomeBlueTag1 = 'HomeBlueTag1',
  HomeBlueTag2 = 'HomeBlueTag2',
  HomeBlueTag3 = 'HomeBlueTag3',

  HomeRiskTitle = 'HomeRiskTitle',
  HomeRiskDetail = 'HomeRiskDetail',
  HomeRiskCaption1 = 'HomeRiskCaption1',
  HomeRiskCaption2 = 'HomeRiskCaption2',
  HomeRiskCaption3 = 'HomeRiskCaption3',
  HomeRiskCaption4 = 'HomeRiskCaption4',
  HomeRiskFeature1 = 'HomeRiskFeature1',
  HomeRiskFeature2 = 'HomeRiskFeature2',
  HomeRiskFeature3 = 'HomeRiskFeature3',
  HomeRiskFeature4 = 'HomeRiskFeature4',
  HomeRiskFeature5 = 'HomeRiskFeature5',
  HomeRiskFeature6 = 'HomeRiskFeature6',
  HomeRiskFeature7 = 'HomeRiskFeature7',
  HomeRiskFeature8 = 'HomeRiskFeature8',
  HomeRiskFeature9 = 'HomeRiskFeature9',

  HomeAll = 'HomeAll',
  HomeMilestones = 'HomeMilestones',
  HomeDevelopment = 'HomeDevelopment',

  HomeReportCaption1 = 'HomeReportCaption1',
  HomeReportCaption2 = 'HomeReportCaption2',
  HomeReportCaption3 = 'HomeReportCaption3',
  HomeReportCaption4 = 'HomeReportCaption4',
  HomeNavInquiry = 'HomeNavInquiry',
  HomeAgencyTitle = 'HomeAgencyTitle',
  HomeAgency1 = 'HomeAgency1',
  HomeAgency2 = 'HomeAgency2',
  HomeAgency3 = 'HomeAgency3',

  /** News 新聞 */
  NewsHeader = 'NewsHeader',
  NewsNote = 'NewsNote',
  NewsSource = 'NewsSource',
  

  /** Comment 觀點 */
  CommentPageTitle = 'CommentPageTitle',
  CommentPwd = 'CommentPwd',
  CommentCharts = 'CommentCharts',
  CommentUpdate = 'CommentUpdate',
  CommentReport = 'CommentReport',
  CommentVideo403 = 'CommentVideo403',
  CommentVideo404 = 'CommentVideo404',

  /** Asset 資產總覽 */
  AssetInventoryDetails = 'AssetInventoryDetails',
  AssetAccumulatedGainsLosses = 'AssetAccumulatedGainsLosses',
  AssetNoteTitle = 'AssetNoteTitle',
  AssetNote_1 = 'AssetNote_1',
  AssetNote_2 = 'AssetNote_2',
  AssetNote_3 = 'AssetNote_3',
  AssetNote_4 = 'AssetNote_4',
  AssetNote_5 = 'AssetNote_5',
  AssetNote_6 = 'AssetNote_6',
  AssetChargeNote_4 = 'AssetChargeNote_4',
  AssetInitialDealingDate = 'AssetInitialDealingDate',
  AssetHistoricalTransactionDetails = 'AssetHistoricalTransactionDetails',
  AssetNAVDate = 'AssetNAVDate',
  AssetClient = 'AssetClient',
  AssetInventoryRefMarketValue = 'AssetInventoryRefMarketValue',
  AssetInventoryCost = 'AssetInventoryCost',
  AssetUnrealizedGainsLosses = 'AssetUnrealizedGainsLosses',
  AssetAccumGainsLosses = 'AssetAccumGainsLosses',
  AssetEstimateNAV = 'AssetEstimateNAV',
  
  
  /** Table 表格欄位 */
  TableUnrealized = 'TableUnrealized',
  TableCurrency = 'TableCurrency',
  TableDealingDate = 'TableDealingDate',
  TableInitialDealingDate = 'TableInitialDealingDate',
  TableDealingNAV = 'TableDealingNAV',
  TableFinancialAdviser = 'TableFinancialAdviser',
  TableSubscriptionAmount = 'TableSubscriptionAmount',
  TableRedemptionAmount = 'TableRedemptionAmount',
  TableDealingShares = 'TableDealingShares',
  TableAmount = 'TableAmount',
  TableSubscriptionFee = 'TableSubscriptionFee',
  TableRedemptionCharge = 'TableRedemptionCharge',
  TablePage = 'TablePage',
  TableAccumRealizedUnrealized = 'TableAccumRealizedUnrealized',
  TableAccumRealizedUnrealized_2 = 'TableAccumRealizedUnrealized_2',
  TableSharesHeld = 'TableSharesHeld',
  TableAverageCost = 'TableAverageCost',
  TableTransactionRecord = 'TableTransactionRecord',
  TableClient = 'TableClient',
  TableInventoryMarketValue = 'TableInventoryMarketValue',
  TableAccumRedemptionAmount = 'TableAccumRedemptionAmount',
  TableAccumSubscriptionAmount = 'TableAccumSubscriptionAmount',
  TableAccumSubscriptionFee = 'TableAccumSubscriptionFee',
  TableRefAccumRedemptionCharge = 'TableRefAccumRedemptionCharge',
  TableAccumNetGainsLosses = 'TableAccumNetGainsLosses',
  TableRefInvestmentReturnRate = 'TableRefInvestmentReturnRate',
  TableEstimateRedemptionCharge = 'TableEstimateRedemptionCharge',
  TableDate = 'TableDate',
  TableFundName = 'TableFundName',
  TableFund = 'TableFund',
  TableRefNAV = 'TableRefNAV',
  TableRefMarketValue = 'TableRefMarketValue',
  TableRefAccumGainsLosses = 'TableRefAccumGainsLosses',
  
  
  /** Form 文件相關 */
  FormSubscription = 'FormSubscription',
  FormRedemption = 'FormRedemption',
  FormClient = 'FormClient',
  FormAgent = 'FormAgent',
  FormKYCRelated = 'FormKYCRelated',
  FormFileName = 'FormFileName',
  FormUpdateDate = 'FormUpdateDate',
  FormTopTitle = 'FormTopTitle',
  FormPlaceholder = 'FormPlaceholder',
  
  /** Recruit 人才招募 */
  RecruitNoJob = 'RecruitNoJob',

  /** Notify 通知 */
  NotifyTitle = 'NotifyTitle',
  NotifyTabText = 'NotifyTabText',
  BulletInTabText = 'BulletInTabText',
  NotifyEmptyDataTitle = 'NotifyEmptyDataTitle',

  /** Dialog  */
  Dialog_AccessRestricted = 'Dialog_AccessRestricted',
  Dialog_LoginToView = 'Dialog_LoginToView',
  Dialog_DearUser = 'Dialog_DearUser',

  Dialog_BrowserUpgrade_Title = 'Dialog_BrowserUpgrade_Title',
  Dialog_BrowserUpgrade_Message = 'Dialog_BrowserUpgrade_Message',
  Dialog_BrowserUpgrade_Action = 'Dialog_BrowserUpgrade_Action',

  /** Footer */
  FooterCaption1 = 'FooterCaption1',
  FooterCaption2 = 'FooterCaption2',
  FooterCaption3 = 'FooterCaption3',
  FooterCaption4 = 'FooterCaption4',

  /** 交易相關用語 */
  NormalSwitch = 'normal switch',
  SameMonthSwitch = 'same month switch',
  SwitchCarryFwd = 'carry fwd',
  SwitchNoCarryFwd = 'no carry fwd',
}