import { ITrade } from '@/api/dealings'
import { IMember } from '@/api/members'
import { getTradeStatusDisplay, SpecVersion } from '@/api/dealings/TradeStatus'

type TradeType = 'SBS' | 'RDM' | 'SWITCH'

export enum TradeAction {
  NotifyFile = 'notify_file_submission',
  NotifyRefund = 'notify_refund',
  ConfirmCash = 'confirm_cash_received',
  ConfirmFile = 'confirm_file_received',
  ConfirmFax = 'confirm_fax_sent',
  ConfirmContract = 'confirm_contract_note',
  ConfirmCancel = 'confirm_cancel',
}

export enum TradeOperation {
  Edit = 'trade_edit', // 編輯交易
  Update = 'trade_update', // 更新交易
  View = 'trade_view', // 查看交易
  Review = 'trade_review', // 查看訂單
  Deliver = 'trade_deliver', // 遞件
  Approve = 'trade_approve', // 審核
  Delete = 'trade_delete', // 刪除
  Reject = 'trade_reject', // 退件
  Cancel = 'trade_cancel', // 註銷
}

interface TradeActionDefinition {
  name: string
  code: TradeAction
  method: string
  subject: string
  context: TradeType[]
}

export enum TradeActionState {
  /** 不可操作也不顯示 */
  Locked = -3,
  /** 未開放 */
  NotAvailable = -2,
  /** 等待中 */
  Wait = -1,
  /** 未收到, 未通知, 未確認 */
  Ready = 0,
  /** 已收到、已通知、已確認，可再次操作 */
  Done = 1,
  /** 已收到、已通知、已確認，不可再次操作 */
  DoneLocked = 2,
}

const TradeActions: TradeActionDefinition[] = [
  /** 通知對方寄出正本 */
  {
    name: '正本通知',
    method: 'notify',
    subject: 'file',
    code: TradeAction.NotifyFile,
    context: ['SBS', 'RDM', 'SWITCH']
  },
  /** 通知贖回放款 */
  {
    name: '贖回放款通知',
    method: 'notify',
    subject: 'refund',
    code: TradeAction.NotifyRefund,
    context: ['RDM']
  },
  /** 確認到款 */
  {
    name: '到款確認',
    method: 'confirm',
    subject: 'cash',
    code: TradeAction.ConfirmCash,
    context: ['SBS']
  },
  /** 確認收到正本 */
  {
    name: '收到正本',
    method: 'confirm',
    subject: 'file',
    code: TradeAction.ConfirmFile,
    context: ['SBS', 'RDM', 'SWITCH']
  },
  /** 註記已下單保銀 */
  {
    name: '下單保銀',
    method: 'confirm',
    subject: 'fax',
    code: TradeAction.ConfirmFax,
    context: ['SBS', 'RDM', 'SWITCH']
  },
  /** 確認 Contract Note 無誤 */
  {
    name: '到款確認',
    method: 'confirm',
    subject: 'contract_note',
    code: TradeAction.ConfirmContract,
    context: ['SBS', 'RDM', 'SWITCH']
  },
  /** 確認 Contract Note 無誤 */
  {
    name: '收到註銷正本',
    method: 'confirm',
    subject: 'cancel',
    code: TradeAction.ConfirmCancel,
    context: ['SBS', 'RDM', 'SWITCH']
  },
]

export function getTradeAction(code: TradeAction) {
  return TradeActions.find(t => t.code === code)
}

export function getTradeActions(type: TradeType) {
  return TradeActions.filter(t => t.context.includes(type))
}

/**
 * 取得交易訂單的可操作狀態
 * @param trade 目前訂單
 * @param action 查詢的操作
 */
export function getTradeActionState(trade: ITrade, action: TradeAction | TradeOperation, member?: IMember | null): TradeActionState {
  const specVersion = trade.has_form === false ? SpecVersion.v221 : undefined // 針對 2.2.1 交易使用舊版狀態流程
  const info = getTradeStatusDisplay(trade.dealing_type, trade.status, member, specVersion)
  const actionBag = info?.action
  if (trade && info) {
    const isDone = (info.doneActions === 'ALL') || info.doneActions?.includes(action)
    const isLock = (info.lockActions === 'ALL') || info.lockActions?.includes(action)
    if (isLock && isDone) {
      // 已完成且鎖住的不可再動
      return TradeActionState.DoneLocked
    } else if (isDone) {
      // 已完成的操作 (視情況可以再次操作)
      return TradeActionState.Done
    } else if (isLock) {
      // 鎖住的操作 (不顯示)
      return TradeActionState.Locked
    } else if (actionBag?.action === action) {
      if (actionBag?.waits && !actionBag.waits(trade)) {
        return TradeActionState.Wait
      } else {
        return TradeActionState.Ready
      }
    } else {
      // 未開放的操作
      return TradeActionState.NotAvailable
    }
  } else {
    return TradeActionState.Locked
  }
}