/**
 * 登入流程
 */

import { IMember } from '@/api/members'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

interface useLoginOptions {
  success?: boolean
  fromPath?: string
  manually?: boolean // 手動登入，登入後將自動分流
  navigationGuard?: {
    to: RouteLocationNormalized,
    next: NavigationGuardNext,
  }
}

export const useLogin = ({ success, fromPath, navigationGuard, manually }: useLoginOptions = {}) => new Promise<boolean>((resolve) => {

  const routeBeforeLogin = useCookie<string>('routeBeforeLogin', { default: () => ref('') })

  // 設定來源參照頁
  function setReferrer(path: string) {
    // 手動登入不紀錄登入前頁面
    if (!manually) routeBeforeLogin.value = path
    else routeBeforeLogin.value = ''
  }

  function goReferrer(member?: IMember | null) {
    const localePath = useLocalePath()
    let referrer = routeBeforeLogin.value

    // 根據登入前的轉跳頁面 (Chatbot)
    if (!referrer && document.referrer && document.referrer.indexOf(window.location.origin) === 0) {
      referrer = document.referrer.replace(window.location.origin, '')
    }
    // 依據會員自動分流
    else if (!referrer && member) {
      // DEMO 帳號到基金總覽
      if (member.isDemoAccount) referrer = localePath({ name: 'funds' })
      // agent 到經理人觀點
      else if (member.group === 'agent') referrer = localePath({ name: 'comments' })
      // colleague 到基金總覽
      else if (member.group === 'staff') referrer = localePath({ name: 'funds' })
      else if (member.group === 'holder') {
        // holder (有庫存) 到基金總覽
        if (member.inStock) referrer = localePath({ name: 'funds' })
        // holder (曾申購過但無庫存) 到歷史交易明細
        if (member.isTraded && !member.inStock) referrer = localePath({ name: 'my-history' })
      }
    }
    // 預設是回到首頁
    if (!referrer) {
      referrer = localePath('/')
    }
    // 如果先前頁面是會員相關，那就跳回首頁
    if (referrer.match(/member\/(forget|signin|signup|passwd)/)) {
      referrer = localePath('/')
    }
    // 如果需要切換語言，則自動更換導向的語言
    const anotherLang = needAnotherLanguage(member)
    if (anotherLang) {
      referrer = changeRouteToLang(referrer, anotherLang)
      switchLocale(anotherLang)
    }
    // 前往且清除紀錄
    useRouter().replace(referrer)
    routeBeforeLogin.value = ''
  }

  // 需要換另一個語言
  function needAnotherLanguage(member?: IMember | null): 'en' | 'zh' | null {
    if (!member) return null
    const { locale } = useNuxtApp().$i18n
    const currentLocale = locale.value as 'zh' | 'en'
    const availableLocale = member.locales[0]
    if (availableLocale && member && !member.locales.includes(currentLocale)) {
      return availableLocale
    }
    return null
  }

  // 更換語系並刷新權限
  function switchLocale(locale: 'en' | 'zh') {
    const { setLocale } = useNuxtApp().$i18n
    setLocale(locale)
    useMember().value = null
    useMemberAsync({ navigationGuard })
  }

  // 要有更聰明的方法來轉換
  function changeRouteToLang(routePath: string, lang: 'zh' | 'en') {
    return routePath.replace(/^\/?(zh|en)/, `/${lang}`)
  }

  // 指定登入前頁面
  if (fromPath) {
    routeBeforeLogin.value = fromPath
    return
  }

  // 登入完成，依身分進行分流
  if (success) {
    // GA: 紀錄成功登入的事件
    const gtag = useGtag()
    const member = useMember()
    gtag('event', 'login', {
      user_id: member.value?.client_id
    })

    // Tracker: 紀錄成功登入的事件
    const tracker = useEventTracker()
    tracker.sendEvent('login')

    goReferrer(member.value)
    resolve(true) // 登入流程完成
    return
  }

  // 使用 navigationGuard
  if (navigationGuard) {
    setReferrer(navigationGuard.to.fullPath)
    navigationGuard.next(useLocalePath()({ path: '/member/signin' }))
    return
  }

  // 紀錄登入前頁面
  setReferrer(useRoute().fullPath)
  navigateTo(useLocalePath()({ path: '/member/signin' }))

})