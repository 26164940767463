import { createAction } from '@/utils/api'

import type { 
  IMember, 
  ISession, 
  ISignupForm,
  ISignupResponse,
  ISignupError,
  ISigninResponse,
  ISigninRequest,
  ISigninError,
} from './types'

export * from './types'

// import './mock'

/**
 * 會員登入
 */
export const memberSignin = createAction<
  ISigninResponse,
  ISigninRequest,
  ISigninError
>('POST', '/api/:locale/members/signin')

export const memberSigninMock = createAction<
  ISigninResponse,
  ISigninRequest
>('POST', '/api/:locale/members/signin?mock=1')

/**
 * 會員登出
 */
export const memberSignout = createAction<
{}
>('POST', '/api/:locale/members/signout')


/**
 * 註冊會員，或是檢查註冊資訊
 */
export const memberSignup = createAction<
  // Response
  ISignupResponse,
  ISignupForm,
  ISignupError
>('POST', '/api/:locale/members/signup')

/**
 * 取得會員資料
 */
export const memberAuthorization = createAction<
  // Response
  {
    member: IMember
    session: ISession
  }
>('GET', '/api/:locale/members/me')

/**
 * 更改密碼
 */
export const memberPasswd = createAction<
  // Response
  {
    message: string
  },
  {
    password_current: string
    password: string
    password_confirmation: string
  }
>('POST', '/api/:locale/members/passwd')

/**
 * 忘記密碼：申請
 */
export const memberForgetPassword = createAction<
  {
    message: string
  },
  {
    client_id: string
    client_name: string
  }
>('POST', '/api/:locale/members/forget')

/**
 * 忘記密碼：設定
 */
export const memberForgetPasswordReset = createAction<
  {
    message: string
  },
  {
    usercode: string
    token: string
    password?: string
    password_confirmation?: string
  }
>('POST', '/api/:locale/members/forget')