// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css'

SwiperCore.use([Autoplay, Navigation, Pagination, A11y])

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('Swiper', Swiper);
  nuxtApp.vueApp.component('SwiperSlide', SwiperSlide);
})