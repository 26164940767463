import type { Privilege } from '@/lib/privilege'

export enum MemberHolderType {
  Personal = '個人投資者',
  Joint = '聯名戶',
  Institutional = '機構投資者',
  Nominee = '專戶(Nominee)',
}

export enum MemberGroup {
  Holder = 'holder',
  Agent = 'agent',
  Staff = 'staff',
  Admin = 'admin',
}
export interface IMember {
  id: any
  name: string
  client_id: string
  email: string
  phone: string
  group: MemberGroup
  holder_type?: MemberHolderType
  locales: Array<'en' | 'zh'>
  privileges: Privilege[]
  inStock: boolean
  isTraded: boolean
  isDemoAccount?: boolean
  agentService: boolean
  nominee: boolean
  reportableRate?: boolean // 可以回報「認購手續費加權平均」(nominee限定!)
}

export interface ISession {
  name: string
  platform: string
  expire_at: string
}

export interface ISignupForm {
  client_id: string
  client_name: string
  usercode: string
  password: string
  password_confirmation: string
  email: string
  phone: string
  confirmed?: boolean
}

export interface ISignupResponse {
  member?: IMember
  token?: string
  session?: ISession
  message: string
  pending?: number | boolean
}

export interface ISignupError {
  error: 1
  message: string
  errors?: {
    client_id?: string
    client_name?: string
    usercode?: string
    password?: string
    email?: string
    phone?: string
    confirmed?: string
  }
}

export interface ISigninResponse {
  member: IMember & { force: boolean }
  token: string
  session: ISession
}

export interface ISigninRequest {
  usercode: string
  password?: string | null
  force?: boolean | null
  otp_code?: string | null
  platform?: string | null
  platform_version?: string | null
}

export type IOTPSigninError = {
  // 202
  otp_sent_to_email?: string;
  otp_sent_to_sms?: string;
  otp_checksum?: string;
} & {
  // 401
  otp_required?: number;
  otp_expired?: number;
};

export type ISigninError = {
  error: 1;
  message?: string;
  failed_attempts?: number;
  reason?: string; // 'RETRY_OVER_LIMIT';
} & IOTPSigninError;
